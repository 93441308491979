"use client";

import { i18nConfig } from "../../i18nConfig";
import { usePathname } from "next/navigation";
import { translations } from "./translations";
import { NotFoundPage } from "../../components/NotFoundPage/NotFoundPage";

export default function NotFound() {
  const { locales, defaultLocale } = i18nConfig;
  const pathname = usePathname();

  const locale =
    locales.find((locale) => pathname.startsWith(`/${locale}/`) || pathname === `/${locale}`) ??
    defaultLocale;

  const dictionary = translations[locale];

  return (
    <NotFoundPage
      code={"404"}
      title={dictionary.notFoundPage.title}
      text={dictionary.notFoundPage.text}
      homeButtonLabel={dictionary.notFoundPage.homeButtonLabel}
      homeButtonUrl={`/${locale}`}
    />
  );
}
