import Link from "next/link";
import classNames from "classnames/bind";
import styles from "./NotFoundPage.module.css";

const cx = classNames.bind(styles);

export function NotFoundPage({
  code,
  title,
  text,
  homeButtonLabel,
  homeButtonUrl,
}: {
  code?: string | number;
  title: string;
  text?: string;
  homeButtonLabel?: string;
  homeButtonUrl?: string;
}) {
  return (
    <div className={cx("errorpage")}>
      <div className={cx("inner")}>
        {code && <span className={cx("code")}>{code}</span>}
        <h1 className={cx("title")}>{title}</h1>
        {text && (
          <div className={cx("text")}>
            <p>{text}</p>
          </div>
        )}
        <div className={cx("navigation")}>
          <Link className={cx("link")} href={homeButtonUrl ?? `/`}>
            {homeButtonLabel}
          </Link>
        </div>
      </div>
    </div>
  );
}
