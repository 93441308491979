export const languages = ["de", "en"] as const;

const de = {
  languages: {
    en: "Englisch",
    de: "Deutsch",
  },
  cookieConsent: {
    noConsentGivenVideo: "Bitte aktivieren Sie Cookies, um dieses Video anzusehen",
  },
  notFoundPage: {
    title: "Seite nicht gefunden",
    text: "Leider konnten wir die von Ihnen gesuchte Seite nicht finden.",
    homeButtonLabel: "Startseite",
  },
  errorPage: {
    title: "Etwas ist schief gelaufen!",
    resetButtonLabel: "Versuchen Sie es erneut",
    homeButtonLabel: "Startseite",
  },
  navigation: {
    navLabel: "seite",
    mainMenuLabel: "navigation",
    languageMenuLabel: "sprache",
    homepageLabel: "Startseite",
  },
  labels: {
    miscellanous: {
      project: "Projekt",
      projects: "Projekte",
      article: "News Artikel",
      articles: "News",
      page: "Seite",
    },
    newsArticleList: {
      readMore: "Weiterlesen",
    },
    projectList: {
      projectName: "Projekt",
      year: "Jahr",
      location: "Ort",
    },
    projectDownloads: {
      blockTitle: "Downloads",
    },
    projectAwards: {
      award: "Auszeichnung",
      year: "Jahr",
      project: "Projekt",
      placing: "Belegung",
    },
    projectDetails: {
      blockTitle: "Projektdaten",
      client: "Auftraggebende",
      categories: "Typologie",
      location: "Ort",
      grossFloorspace: "Brutto-Grundfläche",
      team: "Team",
      photos: "Fotos",
      architects: "Architekten",
      landscaping: "Landschaftsplanung",
      servicePhases: "Leistungsphasen",
      category: "Art",
      competition: "Wettbewerb",
      structuralEngineering: "Statik",
      fireSafety: "Brandschutz",
      technicalBuildingServices: "Technische Gebäudeausrüstung",
      completion: "Fertigstellung",
      energyConcept: "Energiekonzept",
      typology: "Typologie",
      projectManagement: "Projektmanagement",
      height: "Höhe",
      diameter: "Durchmesser",
      acoustics: "Akustik",
      lifecycleAssessment: "Lebenszyklusanalyse",
      award: "Auszeichnung",
      constructionMeasurementResearch: "Bauvermessung und Bauforschung",
      constructionCosts: "Baukosten",
      constructionManagement: "Bauleitung",
      exhibitionDesign: "Ausstellungsgestaltung",
      grossBuildingVolume: "Brutto-Rauminhalt",
      timberConstruction: "Holzbau",
      housingUnits: "Wohneinheiten",
      interiorDesigner: "Innenarchitekt",
      joinery: "Zimmerei",
      materialExchange: "Baustoffaustausch",
      partners: "Beteiligte",
      research: "Forschung",
      restoringExamination: "Restauratorische Untersuchung",
      shellConstruction: "Rohbau",
      timePeriod: "Zeitraum",
      urbanPlanning: "Stadtplanung",
      visualisation: "Visualisierung",
      sponsorInvestorCompetition: "Sponsor of the investor competition",
    },
    cradleToCradle: {
      blockTitle: "Cradle to Cradle Kompass",
      materialsAndResources: "Materialien/Ressourcen",
      energySupply: "Energieversorgung",
      waterManagement: "Wassermanagement",
      maintenance: "Instandhaltung",
      socialValues: "Soziale Werte",
      diversity: "Diversität",
    },
    imageGalleryBlock: {
      carouselLabel: "Bilder",
    },
    carouselGeneric: {
      next: "Nächstes",
      prev: "Vorheriges",
      carouselRoleDescription: "karussell",
      controlsLabel: "Schiebesteuerungsbereich",
      slideRoleDescription: "Folie",
      slideLabel: "Folie {{count}} von {{total}}",
    },
    projectBlock: {
      chipListLabel: "filter",
      toggleLabel: "ansicht wechseln",
    },
    projectGallery: {
      blockTitle: "Ausgewählte Projekte",
    },
    footer: {
      contact: "Kontakt",
    },
  },
};

const en: typeof de = {
  languages: {
    en: "English",
    de: "German",
  },
  cookieConsent: {
    noConsentGivenVideo: "Please enable Cookies to view video",
  },
  notFoundPage: {
    title: "Page not found",
    text: "Sorry, we couldn’t find the page you’re looking for.",
    homeButtonLabel: "Home",
  },
  errorPage: {
    title: "Something went wrong!",
    resetButtonLabel: "Try again",
    homeButtonLabel: "Home",
  },
  navigation: {
    navLabel: "site",
    mainMenuLabel: "navigation",
    languageMenuLabel: "language",
    homepageLabel: "Home",
  },
  labels: {
    miscellanous: {
      project: "Project",
      projects: "Projects",
      article: "News Article",
      articles: "News",
      page: "Page",
    },
    newsArticleList: {
      readMore: "Read More",
    },
    projectList: {
      projectName: "Project Name",
      year: "Year",
      location: "Location",
    },
    projectDownloads: {
      blockTitle: "Downloads",
    },
    projectAwards: {
      award: "Award",
      year: "Year",
      project: "Project",
      placing: "Placing",
    },
    projectDetails: {
      blockTitle: "Project Details",
      client: "Client",
      categories: "Category",
      location: "Location",
      grossFloorspace: "Gross floor area",
      team: "Team",
      photos: "Photos",
      architects: "Architects",
      landscaping: "Landscaping",
      servicePhases: "Service Phases",
      category: "Category",
      competition: "Competition",
      structuralEngineering: "Structural engineering",
      fireSafety: "Fire safety",
      technicalBuildingServices: "Technical building services",
      completion: "Completion",
      energyConcept: "Energy concept",
      typology: "Typology",
      projectManagement: "Project Management",
      height: "Height",
      diameter: "Diameter",
      acoustics: "Acoustics",
      lifecycleAssessment: "Lifecycle Assessment",
      award: "Award",
      constructionMeasurementResearch: "Construction measurement and research",
      constructionCosts: "Construction cost",
      constructionManagement: "Construction management",
      exhibitionDesign: "Exhibition design",
      grossBuildingVolume: "Gross building volume",
      timberConstruction: "Timber construction",
      housingUnits: "Housing units",
      interiorDesigner: "Interior designer",
      joinery: "Joinery",
      materialExchange: "Material exchange",
      partners: "Partners",
      research: "Research",
      restoringExamination: "Restoring examination",
      shellConstruction: "Shell construction",
      timePeriod: "Time period",
      urbanPlanning: "Urban planning",
      visualisation: "Visualisation",
      sponsorInvestorCompetition: "Sponsor of the investor competition",
    },
    cradleToCradle: {
      blockTitle: "Cradle to Cradle Compass",
      materialsAndResources: "Materials/Resources",
      energySupply: "Energy Supply",
      waterManagement: "Water Management",
      maintenance: "Maintenance",
      socialValues: "Social Values",
      diversity: "Diversity",
    },
    imageGalleryBlock: {
      carouselLabel: "Images",
    },
    carouselGeneric: {
      next: "Next",
      prev: "Previous",
      carouselRoleDescription: "carousel",
      controlsLabel: "Slide Controls",
      slideRoleDescription: "slide",
      slideLabel: "Slide {{count}} of {{total}}",
    },
    projectBlock: {
      chipListLabel: "filter",
      toggleLabel: "change view",
    },
    projectGallery: {
      blockTitle: "Selected projects",
    },
    footer: {
      contact: "Contact",
    },
  },
};

export const translations = {
  en,
  de,
};
